<template>
  <div class="Center">
    <nav-vue :Apathindex="1" />
    <div class="banner">
      <img class="banner__img" :src="config.information_banner" alt="">
    </div>
    <img v-if="!isphone" class="content__could" src="@/assets/image/rd/could.png" alt="" />
    <div class="content">
      <div class="content__box" v-loading="loadings">
        <div class="content__box__nav">信息中心/ <span @click="$router.go(-1)">{{ className }}</span> /详情</div>
        <div class="content__box__title">{{ content.title }}</div>
        <div class="content__box__date">{{ getLocalTime(content.updatetime) }}</div>
        <div class="content__box__info" v-html="content.content"></div>
      </div>
    </div>
    <footer-vue></footer-vue>
  </div>
</template>

<script>
import NavVue from '../../components/nav.vue'
import FooterVue from '../../components/footer.vue'
import { informationRead } from '@/uilt/api.js'
import config from '../../mixin/config'
export default {
  components: { NavVue, FooterVue },
  data() {
    return {
      listActive: 0,
      list: ['和锦新闻', '门店动态', '行业动态', '采购招标', '职位招聘'],
      className: this.$route.query.className,
      content: {
        title: '',
        content: '',
        updatetime: ''
      },
      loadings: true
    }
  },
  mixins: [config],
  created() {
    this.getinformationRead()
  },
  methods: {
    Onlist(e) {
      this.listActive = e
    },
    GoContent(e) {
      this.$route.push({
        path: '/informationContent'
      })
    },
    getLocalTime(nS) {
      return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/, ' ');
    },
    getinformationRead() {
      this.loadings = true
      informationRead({ id: this.$route.query.id }).then(res => {
        this.content = res
        this.loadings = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (min-width:769px) {
  .Center {}

  .banner {
    width: 100%;
    height: 400px;

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    position: relative;
    width: 100%;

    &__lone {
      height: 100%;
      width: 2px;
    }

    &__could {
      width: 406px;
      height: 482px;
      position: absolute;
      top: 770px;
      left: 0;
      z-index: 0;
      pointer-events: none;
    }

    &__box {
      width: 1400px;
      min-height: 700px;
      height: auto;
      margin: 51px auto 57px;
      background-color: #fff;
      padding: 45px 112px 158px 100px;
      box-sizing: border-box;
      z-index: 99;
      text-align: center;

      &__nav {
        text-align: left;
        height: 14px;
        font-size: 14px;
        font-family: Songti SC;
        font-weight: 400;
        color: #555555;

        span {
          cursor: pointer;

          &:hover {
            color: rgb(172, 172, 172);
          }

        }
      }

      &__title {
        font-size: 20px;
        font-family: Songti SC;
        font-weight: bold;
        color: #70553C;
        margin-top: 16px;
      }

      &__date {
        height: 10px;
        font-size: 14px;
        font-family: Songti SC;
        font-weight: 400;
        color: #333333;
        margin-top: 8px;
        margin-bottom: 24px;
      }

      &__info {
        text-align: left;
        font-family: Songti SC;
        p {
          font-size: 16px;
          font-family: Songti SC;
          font-weight: 400;
          color: #333333;
          line-height: 28px;

        }
      }


      img {
        width: 386px;
        height: 257px;
        object-fit: cover;
      }
    }
  }
}

@media screen and (max-width:768px) {
  .Center {}

  .banner {
    width: 100%;
    height: 400px;

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .content {
    position: relative;
    width: 100%;

    &__lone {
      height: 100%;
      width: 2px;
    }

    &__could {
      width: 406px;
      height: 482px;
      position: absolute;
      top: 770px;
      left: 0;
      z-index: 0;
      pointer-events: none;
    }

    &__box {

      min-height: 900px;
      height: auto;
      padding: 45px 45px 158px 45px;
      box-sizing: border-box;
      z-index: 99;
      text-align: center;
      &__info {
        text-align: left;
        font-family: Songti SC;
        p {
          font-size: 16px;
         
          font-weight: 400;
          color: #333333;
          line-height: 28px;

        }
      }

      &__nav {

        text-align: left;
        height: 14px;
        font-size: 14px;
        font-family: Songti SC;
        font-weight: 400;
        color: #555555;

        span {
          cursor: pointer;

          &:hover {
            color: rgb(172, 172, 172);
          }

        }
      }

      &__title {
        font-size: 20px;
        font-family: Songti SC;
        font-weight: bold;
        color: #70553C;
        margin-top: 49px;
      }

      &__date {
        height: 10px;
        font-size: 14px;
        font-family: Songti SC;
        font-weight: 400;
        color: #333333;
        margin-top: 8px;
        margin-bottom: 24px;
      }

      p {
        font-size: 16px;
        font-family: Songti SC;
        font-weight: 400;
        color: #333333;
        line-height: 28px;
      }

      img {
        width: 499.7px;
        height: 332.5px;
        object-fit: cover;
      }
    }
  }
}
</style>